import * as React from 'react';
import Seo from "../components/seo";
import Layout from '../components/layout';

export class ProjectsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Projects" />
        <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="w-full h-48 relative">
              <iframe 
                src="https://fotoia.es" 
                title="Fotoia.es"
                className="absolute top-0 left-0 w-full h-full"
                style={{pointerEvents: 'none'}}
                sandbox="allow-scripts allow-same-origin"
              />
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2">Fotoia.es</h3>
              <p className="text-gray-700 mb-4">AI-powered photography service that generates professional photos without a physical photographer. Create personalized AI models and get unlimited photos in various styles.</p>
              <a href="https://fotoia.es" className="text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">Visit Fotoia.es</a>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img 
              src="https://images-websitehunt.s3.amazonaws.com/website/Marketting-1.png" 
              alt="Proximovie.com" 
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2">Proximovie.com</h3>
              <p className="text-gray-700 mb-4">A movie recommendation website. It computes and compares text and image embeddings from movie posters, plots, and other metadata to suggest similar films. It's not fancy AI or anything, it compares text from plots, visual features from posters, that kind of stuff. It's old school, embedding comparison. Might help you pick a movie if you're really indecisive.</p>
              <a href="https://proximovie.com" className="text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">Visit Proximovie.com</a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProjectsPage;